export function removeParagraphTags(content: string): string {
  return content
    .replaceAll('<p>', '')
    .replaceAll('</p>', '')
    .replaceAll('</p>\n', '');
}

export function removeLineBreakTags(content: string): string {
  return content
    .replaceAll('<br>', '')
    .replaceAll('<br/>', '')
    .replaceAll(/[\r|\n|\r\n]$/g, ' ')
    .replaceAll(/^[\r|\n|\r\n]/g, ' ')
    .replaceAll('&nbsp;', ' ')
    .trim();
}
